<template>
  <ValidationObserver v-slot="{ invalid, validated }">
    <v-navigation-drawer v-model="showCreateEdit" app clipped right width="900">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
            <v-list-item-title v-else class="title"> New </v-list-item-title>
            <v-list-item-subtitle>BAO Priority</v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            icon
            color="info"
            :loading="loading"
            :disabled="invalid || !validated"
            @click="save()"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon color="secondary" @click="closeCreateEdit()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <v-card flat>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <span class="subtitle-2">Details</span>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="Name" rules="required" immediate>
                  <v-text-field
                    v-model="name"
                    slot-scope="{ errors, valid }"
                    :error-messages="errors"
                    :success="valid"
                    label="Name"
                    hint="A name for your IAO Priority."
                    clearable
                    required
                  />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="Description" rules="required" immediate>
                  <v-textarea
                    v-model="description"
                    slot-scope="{ errors, valid }"
                    label="Description"
                    :error-messages="errors"
                    :success="valid"
                    hint="A description for your IAO Priority."
                    clearable
                    required
                  />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="View Order" rules="required" immediate>
                  <v-text-field
                    v-model="view_order"
                    slot-scope="{ errors, valid }"
                    label="View Order"
                    :error-messages="errors"
                    :success="valid"
                    type="number"
                    hint="Enter a value to indicate the order in which you want this priority to be shown in a list (lowest numbers are shown first)."
                    clearable
                    required
                  />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="Tactical Report Reminder" rules="required" immediate>
                  <v-text-field
                    v-model="tactical_report_reminder"
                    slot-scope="{ errors, valid }"
                    label="Tactical Report Reminder"
                    :error-messages="errors"
                    :success="valid"
                    type="number"
                    hint="Number of hours to send a tactical report reminder to the leader."
                    clearable
                    required
                    min="1"
                  />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="Executive Report Reminder" rules="required" immediate>
                  <v-text-field
                    v-model="executive_report_reminder"
                    slot-scope="{ errors, valid }"
                    label="Executive Report Reminder"
                    :error-messages="errors"
                    :success="valid"
                    type="number"
                    hint="Number of hours to send an executive report reminder to the leader."
                    clearable
                    required
                    min="1"
                  />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <color-picker-input label="Color" v-model="color"></color-picker-input>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="default_incident_priority"
                  label="Default IAO Priority"
                  hint="Check if this IAO Priority should be the default."
                />
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="enabled"
                  label="Enabled"
                  hint="Determines whether this IAO Priority is availible for new IAOs."
                />
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="page_commander"
                  label="Page Leader"
                  hint="Would you like UnStruct to page the leader on IAO creation?"
                />
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="page_liaison"
                  label="Page Liaison"
                  hint="Would you like UnStruct to page the liaison on IAO creation?"
                />
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="page_scribe"
                  label="Page Scribe"
                  hint="Would you like UnStruct to page the scribe on IAO creation?"
                />
              </v-flex>
              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="create_incident_postmortem"></v-checkbox>
                  <div>Create After-Activity Review (AAR) on Closure</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Enable or disable the automatic creation of an After-Activity Review (AAR) upon closure.
                  Please note that this is a beta feature and is still under development.
                </div>
              </v-flex>
              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="create_ai_summaries_in_background"></v-checkbox>
                  <div>Create AI Summaries in the Background</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Enable or disable the creation of auto AI summaries in the background that can be
                  useful for compiling reports, getting status quickly, etc. Please note that this
                  is a beta feature and is still under development.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="auto_post_to_status_page"></v-checkbox>
                  <div>Automatically Post Updates to StatusPage</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Enable or disable automatically posting to the status page. Please note that this
                  is a beta feature and is still under development.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="auto_schedule_postmortem_meeting"></v-checkbox>
                  <div>
                    Automatically Schedule a After-Activity Review (AAR) meeting after IAO is marked as stable
                  </div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Enable or disable automatically scheduling a After-Activity Review (AAR) meeting after the
                  incident. We also invite all the participants to the meeting. Please note that
                  this is a beta feature and is still under development.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="enable_opsgpt_incident_copilot"></v-checkbox>
                  <div>Enable IAO Copilot</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Enable or disable IAO Copilot to be your AI Assistant. Enabling this
                  option would means the bot will interpret your message in Slack if UnStruct's IAO Copilot Bot is explicitly mentioned (@unstruct(bot) - message), and respond back with
                  data that can potentially assist you in navigating your activity to desired outcomes. Please note that
                  this is a beta feature and is still under development.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="unleash_opsgpt_incident_copilot"></v-checkbox>
                  <div>Unleash IAO Copilot</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Enable or disable IAO Copilot to be your AI Assistant. Enabling this
                  option would means OpsGPT bot will interpret all your messages sent in the channel
                  and respond back with data that can potentially assist you in navigating your activity to desired outcomes.
                  Please note that this is a beta feature and is still under development.
                  This feature is different from the previous one as it does not require you to
                  explicitly mention the bot in the message. Use this feature with caution as it
                  can potentially flood the channel with messages. Could be useful for certain use
                  cases where you want to keep the team updated with the latest information.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="enable_opsgpt_incident_copilot_for_tasks"></v-checkbox>
                  <div>Enable IAO Copilot for Tasks</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Enable or disable IAO Copilot to be your AI Assistant. Enabling this
                  option would mean OpsGPT bot will interpret your message and present you with an
                  option to add a task or a follow-up. Please note that this is a beta feature and
                  is still under development.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox
                    v-model="auto_add_participants_based_on_ai_generated_tags"
                  ></v-checkbox>
                  <div>Auto Add Participants Based on AI Generated Tags</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Automatically pull in additional participants if the search filters match based on
                  newly generated AI tag. Please note that this is a beta feature and is still under
                  development.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox
                    v-model="require_manual_confirmation_for_incident_actions"
                  ></v-checkbox>
                  <div>Manual Confirmation for IAO Actions</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  When enabled, manual confirmation is required for IAO actions such as
                  creation, updates, and closure. When disabled, the system will automatically
                  perform these actions based on user commands.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="auto_add_follow_ups_from_recording_transcript"></v-checkbox>
                  <div>Auto Add Follow-Ups from Recording Transcript</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Automatically add Follow-Ups from recording transcripts based on AI
                  classfications. Please note that this is a beta feature and is still under
                  development.
                </div>
              </v-flex>

              <v-flex xs12>
                <v-layout row align-center>
                  <v-checkbox v-model="auto_add_tasks_from_recording_transcript"></v-checkbox>
                  <div>Auto Add Tasks from Recording Transcript</div>
                  <v-chip x-small label color="warning" class="ml-2 beta">
                    <v-icon x-small>mdi-creation</v-icon>
                    Beta</v-chip>
                </v-layout>
                <div class="hint-text">
                  Automatically add tasks from recording transcripts based on AI classfications.
                  Please note that this is a beta feature and is still under development.
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </ValidationObserver>
</template>

<script>
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import { required } from "vee-validate/dist/rules"
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"

import ColorPickerInput from "@/components/ColorPickerInput.vue"

extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  name: "IncidentPriorityNewEditSheet",

  components: {
    ColorPickerInput,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {}
  },

  computed: {
    ...mapFields("incident_priority", [
      "dialogs.showCreateEdit",
      "selected.color",
      "selected.default",
      "selected.description",
      "selected.enabled",
      "selected.executive_report_reminder",
      "selected.id",
      "selected.loading",
      "selected.name",
      "selected.page_commander",
      "selected.page_liaison",
      "selected.page_scribe",
      "selected.project",
      "selected.tactical_report_reminder",
      "selected.view_order",
      "selected.create_incident_postmortem",
      "selected.create_ai_summaries_in_background",
      "selected.require_manual_confirmation_for_incident_actions",
      "selected.enable_opsgpt_incident_copilot",
      "selected.unleash_opsgpt_incident_copilot",
      "selected.enable_opsgpt_incident_copilot_for_tasks",
      "selected.auto_add_participants_based_on_ai_generated_tags",
      "selected.auto_add_follow_ups_from_recording_transcript",
      "selected.auto_add_tasks_from_recording_transcript",
      "selected.auto_post_to_status_page",
      "selected.auto_schedule_postmortem_meeting",
    ]),
    ...mapFields("incident_priority", {
      default_incident_priority: "selected.default",
    }),
    ...mapFields("route", ["query"]),
  },

  methods: {
    ...mapActions("incident_priority", ["save", "closeCreateEdit"]),
  },

  created() {
    if (this.query.project) {
      this.project = { name: this.query.project }
    }
  },
}
</script>

<style scoped>
.beta {
  position: relative;
  top: -5px;
}
.hint-text {
  margin-left: 40px;
  color: grey;
}
</style>
