<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New IAO Priority
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-creation" prominent type="info">
          <div>
            <h3>
              <v-icon class="mr-1" left>mdi-flag</v-icon>
              IAO Priorities Configuration & AI Features in Beta
            </h3>
            <p>Priorities are crucial for categorizing IAOs within UnStruct, offering significant configurability to tailor actions based on the urgency of each IAO.</p>
            <p>Each priority level allows for specific configurations, enabling automated responses to IAOs based on their severity.</p>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="2">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
            <v-card class="ma-3 elevation-2">
              <v-card-title>
                <v-icon large left color="primary">mdi-flag</v-icon>
                {{ item.name }}
                <v-spacer></v-spacer>
                <v-chip v-if="item.enabled" color="success" class="mx-2 my-2">
                    <v-icon left>mdi-lightbulb-on-outline</v-icon>
                    Enabled
                  </v-chip>
                  <v-chip v-if="!item.enabled" color="error" class="mx-2 my-2">
                    <v-icon left>mdi-lightbulb-off-outline</v-icon>
                    Disabled
                  </v-chip>
                  <v-chip v-if="item.default" color="secondary" class="mx-2 my-2">
                    <v-icon left>mdi-star</v-icon>
                    Default
                  </v-chip>
              </v-card-title>
              <v-card-subtitle class="pb-4">
                <div class="chip-container">
                  <v-chip v-if="item.page_commander" icon class="mr-2 ma-2">
                      <v-icon color="primary" class="ml-1" left>mdi-crown</v-icon>
                      Page Leader
                      </v-chip
                    >
                    <v-chip v-if="item.page_liaison" icon class="mr-2 ma-2">
                      <v-icon color="primary" class="ml-1" left>mdi-bridge</v-icon>
                      Page Liaison
                      </v-chip
                    >
                    <v-chip v-if="item.page_scribe" icon class="mr-2 ma-2">
                    <v-icon color="primary" class="ml-1" left>mdi-account-supervisor</v-icon>
                      Page Scribe
                      </v-chip
                    >
                    <v-card-subtitle class="px-4 py-4 mt-5">
                      <v-icon left color="primary">mdi-beta</v-icon>
                      <span class="caption font-weight-bold">Beta AI Features</span>
                    </v-card-subtitle>

                    <div v-if="item.create_incident_postmortem" icon class="mr-2 ma-2">
                      <v-icon color="success" class="ml-1" left>mdi-creation</v-icon>
                      Create After-Activity Review (AAR) Draft
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                      </div>

                    <div v-if="item.create_ai_summaries_in_background" icon class="mr-2 ma-2">
                      <v-icon color="purple" class="ml-1" left>mdi-creation</v-icon>
                      Create AI Summaries in Background
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                      </div>

                    <div v-if="item.enable_opsgpt_incident_copilot" icon class="mr-2 ma-2">
                      <v-icon color="teal" class="ml-1" left>mdi-creation</v-icon>
                      Enable IAO Copilot
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                      </div>

                    <div v-if="item.enable_opsgpt_incident_copilot_for_tasks" icon class="mr-2 ma-2">
                      <v-icon  color="brown" class="ml-1" left>mdi-creation</v-icon>
                      Enable IAO Copilot for Tasks
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                    </div>

                    <div v-if="item.auto_add_participants_based_on_ai_generated_tags" icon class="mr-2 ma-2">
                      <v-icon color="warning" class="ml-1" left>mdi-creation</v-icon>
                      Auto Add Participants Based on AI Tags
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                      </div>

                    <div v-if="item.auto_add_follow_ups_from_recording_transcript" icon class="mr-2 ma-2">
                      <v-icon color="blue-grey" class="ml-1" left>mdi-creation</v-icon>
                      Auto Add Follow-Ups from Transcript
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                    </div>

                    <div v-if="item.auto_add_tasks_from_recording_transcript" icon class="mr-2 ma-2">
                      <v-icon  color="cyan" class="ml-1" left>mdi-creation</v-icon>
                      Auto Add Tasks from Transcript
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                    </div>

                    <div v-if="item.auto_post_to_status_page" icon class="mr-2 ma-2">
                      <v-icon color="success" class="ml-1" left>mdi-creation</v-icon>
                      Auto Post to IAO Status Page
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                    </div>

                    <div v-if="item.auto_schedule_postmortem_meeting" icon class="mr-2 ma-2">
                      <v-icon color="blue" class="ml-1" left>mdi-creation</v-icon>
                      Auto Schedule After-Activity Review (AAR) Meeting
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                    </div>

                    <div v-if="item.require_manual_confirmation_for_incident_actions" icon class="mr-2 ma-2">
                      <v-icon color="cyan darken-2" class="ml-1" left>mdi-creation</v-icon>
                      Require Manual Confirmation for Actions
                      <v-chip x-small label color="warning" class="ml-2 beta">
                        <v-icon x-small left>mdi-creation</v-icon>
                        Beta</v-chip>
                    </div>
                </div>
              </v-card-subtitle>
              <v-card-text class="px-4">
                      <!-- Funny Danger Small Badge/Chip -->
                      <v-badge
                        v-if="item.unleash_opsgpt_incident_copilot"
                        left
                        color="error"
                        dot
                        overlap
                        offset-x="12"
                        offset-y="12"
                      >
                        <template v-slot:badge>
                          <v-icon small class="mt-1">mdi-domain</v-icon>
                        </template>
                        <v-chip v-if="item.unleash_opsgpt_incident_copilot" dark color="error" class="mx-2 my-2">
                          <v-icon class="mr-1" left>mdi-creation</v-icon>
                          <v-icon left>mdi-alien</v-icon>
                          Unleash IAO Copilot
                          <v-chip x-small label color="warning" class="ml-2 beta">
                            <v-icon x-small left>mdi-creation</v-icon>
                            Beta</v-chip>
                        </v-chip>
                      </v-badge>
                      <!-- Add more features or chips here -->
                    </v-card-text>
              <v-card-text class="pt-0">
                <v-icon left>mdi-axis-arrow-info</v-icon>
                {{ truncateText(item.description) }}
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="primary" icon @click="createEditShow(item)">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import NewEditSheet from "@/incident/priority/NewEditSheet.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"

export default {
  name: "IncidentPriorityTable",

  components: {
    NewEditSheet,
    SettingsBreadcrumbs,
  },

  computed: {
    ...mapFields("incident_priority", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query", "params"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("incident_priority", ["getAll", "createEditShow", "removeShow"]),
    truncateText(text) {
      const maxLength = 100
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text
    },
  },
}
</script>

<style scoped>
.chip-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.beta {
  position: relative;
  top: -5px;
}
</style>
